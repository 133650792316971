import React, { useEffect, useState } from 'react';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import clsx from 'clsx';
import { Button, Hidden, IconButton, makeStyles } from '@material-ui/core';
import { AppBar } from '../app-bar/app-bar.component';
import { Routes } from '../../routes';
import { NavBarBottom } from '../app-bar/nav-bar-bottom.component';
import { NavBarContainer } from '../app-bar/tab-bar.container';
import { CasinoBonusesDto } from '../../models/CasinoLobbyV2/CasinoBonusesDto';
import { GetAmountWithDecimals } from '../functions/common';
import { PlayerSocketServiceClient } from '../services/socket-service';
import { SocketBasicMessageAdmin, SocketBasicRequest, SocketMessageChangeMaxBetAmount } from '../../models/Socket/socket-request';
import { SocketMessageEnum, SocketRooms } from '../../enum/socket/socket-enum';
import { DialogSocketMessagesComponent } from './dialogs/dialog-socket-messages.component';
import { ISocketIdentityStatusResponse, SocketMessagesByDateDto, SocketMessagesDto } from '../../models/Socket/socket-messages';
import { MessageServiceClient } from '../services/messages-service';
import { emptyCashierDialog, ICashierDialog } from '../../models/cashier-transaction';
import { TransactionTypeEnum } from '../../enum/transactions/TransactionTypeEnum';
import { ComponentEnum } from '../../enum/components/component-enum';
import { RouteSiteEnum } from '../../enum/routes/routes-site-enum';
import { ITvChannels } from '../../models/tv-channels';
import { SnackbarKey, useSnackbar } from 'notistack';
import { BitrixServiceClient } from '../services/bitrix-service';
import CloseIcon from '@material-ui/icons/Close';
import { IPlayerSession } from '../../models/player';
import { ICognitoCloseWindow, IIdentityDialog } from '../../models/users';
import { ITransactionContest } from '../../models/Cashier/Transactions/TransactionContest';
import { DialogTransactionContestComponent } from './dialogs/dialog-transaction-contest';
import { MarkerServiceClient } from '../services/marker-service';
import { getHistory } from '../../utils/history.util';
import { IAppSettingsDto } from '../../models/common';
import { getAppSettings } from '../../utils';
declare global {
  interface Window {
    LO: any;
  }
}

const appBarHeight = '64px';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    marginTop: appBarHeight,
    marginBottom: appBarHeight,
  },
  marginTop72: {
    marginTop: '82px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-7px',
    },
  },
}));

const setCloseDialogPostMessage = () => {
  window.parent.postMessage('close_ad_dialog', '*');
};

const setEventListenerBitrix = (set: boolean) => {
  try {
    const elements = document.getElementsByClassName('b24-widget-button-wrapper');

    if (elements.length > 0) {
      const element = elements[0];
      if (set) {
        element.addEventListener('click', setCloseDialogPostMessage);
      } else {
        element.removeEventListener('click', setCloseDialogPostMessage);
      }
    }
  } catch (error) {
    console.log('setEventListenerBitrix', error);
  }
};

const isMobile: boolean = window.innerWidth <= 768;

export const getCurrentPage = () => {
  return window.location.href.split(window.location.origin)[1].replace('/#/', '/');
};

export interface AppRootProps {
  isLoggedIn: boolean;
  user: any;
  userBalance: any;
  tvOnOffResponses: boolean;
  tvUrl: string;
  path: string;
  tourSteps: any;
  rightOpen: boolean;
  promotions: any;
  rollover: any;
  isLoading: boolean;
  casinoBonuses: CasinoBonusesDto[];
  casinoBonus: CasinoBonusesDto | null;
  cashierDialog: ICashierDialog;
  socketMessages: SocketMessagesDto[];
  transactionType: number | null;
  socketMessagesByDate: SocketMessagesByDateDto[];
  tvChannelsList: ITvChannels[];
  runTour: boolean;
  identityDialogOpen: IIdentityDialog;
  shareable_url: string | null;
  window_opened: Window | null;
  transaction_contest: ITransactionContest[];
  openDialogContest: boolean;
  app_settings: IAppSettingsDto;
}
export interface ExternalActionProps {
  getUserBalanceAction: () => void;
  tvOnOffAction: (isOn: boolean) => void;
  SetOpenDialogTransactionContestAction: (data: boolean) => void;
  setTvUrlAction: (ch: string) => void;
  getUserInfoAction: () => void;
  getTourStepsAction: () => void;
  getPromotionAction: () => void;
  getPlayerRolloverAction: (x: any) => void;
  ptpiGetCasinoBonusesAction: () => void;
  ptpiGetCasinoBonusAction: (x: any) => void;
  setCashierDialogAction: (data: any) => void;
  changeUserBalanceAction: (data: any) => void;
  SetSocketMessagesPlayerAction: (data: SocketMessagesDto[]) => void;
  MarkNotifiedAsReadPlayerAction: (data: SocketMessagesDto) => void;
  SetSocketMessagesPlayerByDateAction: (data: SocketMessagesByDateDto[]) => void;
  MarkNotifiedAsReadPlayerByDateAction: (data: SocketMessagesByDateDto) => void;
  getTvChannelsAction: () => void;
  SetRunTourAction: (data: boolean) => void;
  MarkTourAsDisplayedAction: () => void;
  toggleVerifyIdentityDialogAction: (state: IIdentityDialog) => void;
  createCognitoRetryAction: () => void;
  SetRights: (data: ISocketIdentityStatusResponse) => void;
  closeCognitoFlowSessionAction: (data: ICognitoCloseWindow) => void;
  SetMaxBetAmountAction: (data: SocketMessageChangeMaxBetAmount) => void;
  GetTransactionContestAction: () => void;
  SetAppSettingAction: (appSettings: IAppSettingsDto) => void;
  SetChatInitializedAction: (data: boolean) => void;
  clearCognitoUrlAction: () => void;
}

export const AppRoot = ({
  isLoggedIn,
  user,
  toggleVerifyIdentityDialogAction,
  identityDialogOpen,
  createCognitoRetryAction,
  shareable_url,
  userBalance,
  getUserBalanceAction,
  tvOnOffAction,
  tvOnOffResponses,
  setTvUrlAction,
  getUserInfoAction,
  getTourStepsAction,
  tourSteps,
  tvUrl,
  path,
  rightOpen,
  getPromotionAction,
  promotions,
  rollover,
  getPlayerRolloverAction,
  casinoBonuses,
  ptpiGetCasinoBonusesAction,
  casinoBonus,
  isLoading,
  ptpiGetCasinoBonusAction,
  setCashierDialogAction,
  cashierDialog,
  changeUserBalanceAction,
  socketMessages,
  socketMessagesByDate,
  transactionType,
  SetSocketMessagesPlayerAction,
  MarkNotifiedAsReadPlayerAction,
  SetSocketMessagesPlayerByDateAction,
  MarkNotifiedAsReadPlayerByDateAction,
  getTvChannelsAction,
  tvChannelsList,
  runTour,
  window_opened,
  transaction_contest,
  GetTransactionContestAction,
  SetRunTourAction,
  MarkTourAsDisplayedAction,
  SetRights,
  closeCognitoFlowSessionAction,
  SetOpenDialogTransactionContestAction,
  openDialogContest,
  SetMaxBetAmountAction,
  app_settings,
  SetAppSettingAction,
  SetChatInitializedAction,
  clearCognitoUrlAction,
}: ExternalActionProps & AppRootProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<SocketBasicMessageAdmin | null>(null);
  const [cashierSettings, setCashierSettings] = useState<ICashierDialog>(emptyCashierDialog);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayAdd, setDisplayAdd] = useState<boolean>(true);

  useEffect(() => {
    console.log('window_opened', window_opened);
  }, [window_opened]);

  useEffect(() => {
    const local_host = window.location.origin;
    const host_name = local_host.replace('http://', '').replace('https://', '');
    const host = (getAppSettings().hosts ?? []).find((x: IAppSettingsDto) => x.site.url == host_name);
    if (host?.site?.name && host?.site?.url && host?.logo?.url && host?.theme?.url && host?.signup?.url) {
      SetAppSettingAction(host);
    }
  }, [getAppSettings().hosts]);

  useEffect(() => {
    if (app_settings?.theme?.url) {
      document.getElementById('css_url')?.setAttribute('href', app_settings.theme.url);
      document.getElementById('favicon_id')?.setAttribute('href', `//${app_settings.site.url}/${app_settings.logo.url}`);
    }
  }, [app_settings]);

  const action = (snackbarId: SnackbarKey, request: SocketBasicMessageAdmin) => (
    <>
      {request.chat_message && request.message.trim().length > 0 && (
        <Button
          variant='contained'
          color='primary'
          style={{ marginRight: '10px' }}
          onClick={() => {
            BitrixServiceClient.openChat();
            // if (request.chat_message != null && request.chat_message.trim().length > 0) {
            //   BitrixServiceClient.sendMessage(request.chat_message);
            // }
          }}
        >
          Open Chat
        </Button>
      )}

      <IconButton
        aria-label='delete'
        size='small'
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  useEffect(() => {
    MessageServiceClient.SetMessage(socketMessages);
  }, [socketMessages]);

  const whiteListCashierPage = [
    RouteSiteEnum.CASHIER_METHOD.toString(),
    RouteSiteEnum.CASHIER_BONUS.toString(),
    RouteSiteEnum.CASHIER_COMPLETE.toString(),
    RouteSiteEnum.CASHIER_CONFIRM.toString(),
    RouteSiteEnum.CASHIER_INFO.toString(),
    RouteSiteEnum.CASHIER_PAYMENT.toString(),
    RouteSiteEnum.CASHIER_BANK.toString(),
  ];

  useEffect(() => {
    MessageServiceClient.SetMessageByDate(socketMessagesByDate);
  }, [socketMessagesByDate]);

  useEffect(() => {
    const timeOutMinute = setInterval(() => {
      const currentDate = new Date();
      const pst = currentDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      });
      const pstDate = new Date(pst);

      MessageServiceClient.CheckTimeToDisplayMessage(pstDate);
    }, 6000);

    // Casino maintenance message
    // window.parent.postMessage(
    //   {
    //     data: {
    //       data: {
    //         id: '',
    //         action: SocketMessageEnum.SHOWDIALOG,
    //         message: `
    //             Dear VIP Customers!
    //             <br/>
    //             <br/>
    //             We want to inform you that Cash App will be temporarily disabled due to scheduled maintenance. The service will be unavailable from 10:00PM to 6:00AM PST. We apologize for any inconvenience this may cause and appreciate your understanding as we work to improve our service.
    //             <br/>
    //              We thank you for your patience. We'll have everything up and running smoothly again by morning.
    //             <br/>
    //             VIP Team.`,
    //         room: '',
    //         chat_message: '',
    //         showOpenDialogButton: false,
    //       },
    //     },
    //     action: 'message-to-client',
    //   },
    //   '*',
    // );
    return () => {
      clearInterval(timeOutMinute);
    };
  }, []);

  useEffect(() => {
    let currPage = getCurrentPage();

    if (cashierDialog.isShow) {
      currPage = '/cashier';
      if (cashierDialog.transactionType != cashierSettings.transactionType && transactionType != null && transactionType != -1) {
        const depositPage = socketMessages.find(x => x.user_perm_component_id == ComponentEnum.CASHIER_DEPOSITS);
        const wdPage = socketMessages.find(x => x.user_perm_component_id == ComponentEnum.CASHIER_WITHDRAWALS);
        switch (cashierDialog.transactionType) {
          case TransactionTypeEnum.DEPOSIT:
            if (depositPage) currPage = depositPage.component.route;
            break;
          case TransactionTypeEnum.WITHDRAWAL:
            if (wdPage) currPage = wdPage.component.route;
            break;
          default:
            break;
        }
      }
    }

    setCashierSettings(cashierDialog);
    MessageServiceClient.SetMessageToast(currPage);
  }, [cashierDialog]);

  useEffect(() => {
    setTimeout(() => {
      let currPage = getCurrentPage();
      if (!cashierDialog.isShow && transactionType != null && transactionType != -1) {
        currPage = '/cashier';
        const depositPage = socketMessages.find(x => x.user_perm_component_id == ComponentEnum.CASHIER_DEPOSITS);
        const wdPage = socketMessages.find(x => x.user_perm_component_id == ComponentEnum.CASHIER_WITHDRAWALS);
        switch (transactionType) {
          case TransactionTypeEnum.DEPOSIT:
            if (depositPage) currPage = depositPage.component.route;
            break;
          case TransactionTypeEnum.WITHDRAWAL:
            if (wdPage) currPage = wdPage.component.route;
            break;
          default:
            break;
        }
        MessageServiceClient.SetMessageToast(currPage);
      }
    }, 500);
  }, [transactionType]);

  const TourDisplayCheck = (currPage: string) => {
    SetRunTourAction(false);
    let show = false;
    try {
      const tour_display = localStorage.getItem(`tour_display_${user?.username}`);
      if (tour_display) {
        const tmpPage = currPage == '/' ? RouteSiteEnum.SPORTSBOOK : currPage;
        const pages: string[] = JSON.parse(localStorage.getItem(`tour_steps_displayed_${user?.username}`) ?? '[]');
        const pageSelected = pages.find(x => x == tmpPage);
        if (!pageSelected) {
          show = true;
          pages.push(tmpPage);
          localStorage.setItem(`tour_steps_displayed_${user?.username}`, JSON.stringify(pages));
          if (parseInt(tour_display) == 0) {
            MarkTourAsDisplayedAction();
          }
        }
      }
    } catch (error) {
      console.error('Set Page', error);
    }
    setTimeout(() => {
      SetRunTourAction(show);
    }, 1500);
  };

  const ShowAndHideMarkerByRoute = (route: string) => {
    if (route.includes('/#')) {
      route = route.replace('/#', '');
    }
    if (isMobile) {
      MarkerServiceClient.Hide();
    } else {
      if (['/live-sports'].indexOf(route) == -1) {
        MarkerServiceClient.Show();
      } else {
        MarkerServiceClient.Hide();
      }
    }
  };

  useEffect(() => {
    TourDisplayCheck(getCurrentPage());

    setTimeout(() => {
      const Session: SocketBasicRequest = {
        accessToken: user && user.token && user.token.toString().trim().length > 0 ? user.token : null,
        userID: user && user.username && user.username.toString().trim().length > 0 ? user.username : SocketRooms.VIPPUBLIC,
      };

      window.addEventListener('hashchange', function () {
        setTimeout(() => {
          const currPage = getCurrentPage();
          TourDisplayCheck(currPage);
          PlayerSocketServiceClient(Session).UpdateInformation({
            currentPage: window.location.href.split(window.location.origin)[1],
            updated: true,
            updatedAt: new Date(),
          });
          if (whiteListCashierPage.indexOf(currPage) == -1) MessageServiceClient.SetMessageToast(currPage);
          ShowAndHideMarkerByRoute(currPage);
          MarkerServiceClient.setCustomData();
        }, 1000);
      });

      window.addEventListener('locationchange', function () {
        setTimeout(() => {
          const currPage = getCurrentPage();
          TourDisplayCheck(currPage);
          PlayerSocketServiceClient(Session).UpdateInformation({
            currentPage: window.location.href.split(window.location.origin)[1],
            updated: true,
            updatedAt: new Date(),
          });

          MessageServiceClient.SetMessageToast(currPage);
          MarkerServiceClient.setCustomData();
          ShowAndHideMarkerByRoute(currPage);
        }, 1000);
      });
    }, 3000);
  }, []);

  const MarkAsRead = (data: SocketMessagesDto) => {
    MarkNotifiedAsReadPlayerAction(data);
  };

  const handlerDialog = () => {
    setRequest(null);
    setOpen(false);
  };

  const handleIdentityVerification = (data: ISocketIdentityStatusResponse) => {
    if (data.is_success == true) {
      setTimeout(() => {
        toggleVerifyIdentityDialogAction({
          open: true,
          message: 'YOUR ACCOUNT HAS BEEN VERIFIED',
          result: true,
          playerProfileSettingsPaymentMethodBonuses: data.player_profile_settings_payment_method_bonuses || [],
        });
        if (data.rights) {
          try {
            getUserInfoAction();
            const user: IPlayerSession = JSON.parse(localStorage.getItem('user') ?? '{}');
            user.rights = data.rights;
            localStorage.setItem('user', JSON.stringify(user));
            SetRights(data);
          } catch (error) {
            console.log('erros');
          }
        }
      }, 2000);
    } else {
      toggleVerifyIdentityDialogAction({
        open: true,
        message: data.message,
        result: false,
        playerProfileSettingsPaymentMethodBonuses: data.player_profile_settings_payment_method_bonuses || [],
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      userInfo();
    }

    window.addEventListener('onBitrixLiveChat', function () {
      window.parent.postMessage('bitrix_live_chat_initiated', '*');
    });

    //Here detect all message from iframes
    window.addEventListener(
      'message',
      event => {
        //console.log(event);
        if (event.origin.includes('plive.webavanx.com')) if (event.data == 'bet-accepted') getUserBalanceAction();
        // if (event.data?.exi_fMessageType_str == 'exi_onGameRoundFinalized') getUserBalanceAction();
        if (event.data == 'vip_update_balance_pusher') getUserBalanceAction();
        if (event.data == 'bitrix_live_chat_initiated') {
          SetChatInitializedAction(true);
          if (isMobile) {
            setEventListenerBitrix(true);
          }
          console.log('bitrix_live_chat_initiated  x2');
        }
        if (event.data == 'open_identity_dialog') {
          setCashierDialogAction(emptyCashierDialog);
          toggleVerifyIdentityDialogAction({
            open: true,
            message: '',
            result: false,
            playerProfileSettingsPaymentMethodBonuses: [],
          });
        }

        if (event.data == 'go_to_profile') {
          setCashierDialogAction(emptyCashierDialog);
          getHistory().push('/profile');
        }

        if (event.data == 'place_bet_from_cashier_dialog') {
          setCashierDialogAction(emptyCashierDialog);
          getHistory().push('/sportsbook');
        }

        if (event.data == 'close_ad_dialog') {
          setDisplayAdd(false);
          setEventListenerBitrix(false);
        }

        if (event.data == 'hide_dialog') {
          MarkerServiceClient.Hide();
        }

        if (event.data && event.data.data && event.data.action) {
          switch (event.data.action) {
            case 'balance-change': {
              let timeout = 0;
              if (GetAmountWithDecimals(userBalance.RealAvailBalance, 2) < GetAmountWithDecimals(event.data.data.RealAvailBalance, 2))
                timeout = 2500;

              setTimeout(() => {
                changeUserBalanceAction(event.data.data);
              }, timeout);
              break;
            }
            case 'message-to-client': {
              handlerMessageClient({
                ...event.data.data.data,
                showOpenDialogButton: event.data.data.data.showOpenDialogButton ? true : false,
              });
              break;
            }
            case 'set-Messages':
            case 'get-messages-received': {
              SetSocketMessagesPlayerAction(event.data.data.data);
              break;
            }

            case 'set-Messages-by-date':
              SetSocketMessagesPlayerByDateAction(event.data.data.data);
              break;

            case 'mark-as-read-message': {
              MarkAsRead(event.data.data);
              break;
            }

            case 'mark-as-read-message-by-date': {
              MarkNotifiedAsReadPlayerByDateAction(event.data.data);
              break;
            }

            case 'signup-status': {
              console.log('event - signup-status', event.data.data);
              handleIdentityVerification(event.data.data);
              break;
            }

            case 'close-cognito-identity': {
              console.log('close-cognito-identity event', event.data.data);
              closeCognitoFlowSessionAction(event.data.data);
              break;
            }

            case 'message-to-client-api': {
              handlerMessageClient({
                ...event.data.data,
                showOpenDialogButton: event.data.data.showOpenDialogButton ? true : false,
              });
              break;
            }

            case 'call-tour': {
              const currPage = getCurrentPage();
              TourDisplayCheck(currPage);
              break;
            }

            case 'update-max-wager': {
              SetMaxBetAmountAction(event.data.data);
              break;
            }

            case 'setCustomData': {
              MarkerServiceClient.setCustomData();
              break;
            }
            default:
              break;
          }
        }
      },
      false,
    );
    // if (Object.entries(userBalance).length === 0 && isLoggedIn) {
    //   getUserBalanceAction();
    // }

    RemoveFbclid();
  }, []);
  const userInfo = async () => {
    getUserInfoAction();
  };

  const handlerMessageClient = (request: SocketBasicMessageAdmin) => {
    switch (request.action) {
      case SocketMessageEnum.MESSAGE:
        toastr.warning('VIP TEAM MESSAGE.', request.message, { timeOut: 8000 });
        break;
      case SocketMessageEnum.SHOWDIALOG:
        setRequest(request);
        setOpen(true);
        break;
      case SocketMessageEnum.SNACKBAR:
        enqueueSnackbar(request.message, {
          variant: 'default',
          autoHideDuration: 100000,
          action: key => action(key, request),
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const d: any = document.getElementsByClassName('b24-widget-button-inner-container')[1];
      if (d) {
        d?.parentNode.removeChild(d);
      }

      getUserBalanceAction();
      // if (window.LO != null) {
      //   console.log('window.LO =====>', window.LO.visitor);
      //   if (window.LO.visitor) {
      //     const userData = {
      //       'Player Number': user.username,
      //       email: user.username,
      //       'Player Email': user.email,
      //       'Player Type': user.player_type,
      //       Agent: user.agent,
      //       phone: user.phone,
      //       'Identity Verified': user.cognito_match == 1 ? 'Yes' : 'No',
      //       'Sign Up': user.sign_up_date,
      //       'Last Deposit': user.last_deposit_date ?? 'N/A',
      //       'Available Balance': GetAmountWithOutDecimals(userBalance.RealAvailBalance),
      //       'Free Play Balance': GetAmountWithOutDecimals(userBalance.FreePlayAmount),
      //     };
      //     window.LO.visitor.identify(user.username, userData);
      //     console.log('[LO]: Sent User Info');
      //   }
      // }
    }
  }, [path]);

  //Connect to socket for instant notifications
  useEffect(() => {
    setTimeout(() => {
      let userID: string = SocketRooms.VIPPUBLIC;
      let accessToken = null;

      try {
        const userLocalStorage = localStorage.getItem('user');
        if (userLocalStorage) {
          const userLocal: IPlayerSession = JSON.parse(userLocalStorage);
          userID = userLocal.username ? userLocal.username : user.username ? user.username : userID;
          accessToken = userLocal.token;
        }
      } catch (error) {
        console.error('Get User', { error });
      }

      if (isLoggedIn && userID == SocketRooms.VIPPUBLIC) {
        userID = user.username;
        accessToken = user.token;
      }

      PlayerSocketServiceClient({ userID, accessToken }).CreateSocket({ userID, accessToken });
    }, 5000);
  }, []);

  const RemoveFbclid = () => {
    const currentHref = window.location.href;
    if (!currentHref) return;
    if (typeof currentHref !== 'string') return;

    const questionmarkIndex = currentHref.indexOf('?');
    if (questionmarkIndex === -1) return;

    const url = currentHref.substring(0, questionmarkIndex);
    const hashIndex = currentHref.indexOf('#');

    const query =
      hashIndex !== -1
        ? currentHref.substr(questionmarkIndex + 1, hashIndex - questionmarkIndex - 1)
        : currentHref.substr(questionmarkIndex + 1);

    const hash = hashIndex !== -1 ? currentHref.substr(hashIndex + 1) : undefined;

    const params = query.split('&').filter(param => !param.startsWith('fbclid='));

    const newHref = url + (params.length ? '?' + params.join('&') : '') + (hash !== undefined ? '#' + hash : '');
    if (currentHref === newHref) return;

    if (window.history && window.history.replaceState) {
      window.history.replaceState(undefined, '', newHref);
    } else {
      window.location.replace(newHref);
    }
  };

  return (
    <div className={classes.root}>
      {request && open && <DialogSocketMessagesComponent handlerDialog={handlerDialog} setOpen={setOpen} open={open} request={request} />}
      {openDialogContest && (
        <DialogTransactionContestComponent
          GetTransactionContestAction={GetTransactionContestAction}
          data={transaction_contest}
          handlerDialog={() => SetOpenDialogTransactionContestAction(false)}
          isLoading={isLoading}
          open={openDialogContest}
          setOpen={SetOpenDialogTransactionContestAction}
        />
      )}
      {isLoggedIn && (
        <AppBar
          user={user}
          userBalance={userBalance}
          tvOnOffResponses={tvOnOffResponses}
          tvOnOffAction={tvOnOffAction}
          tvUrl={tvUrl}
          setTvUrlAction={setTvUrlAction}
          isTvOn={tvOnOffResponses}
          getTourStepsAction={getTourStepsAction}
          tourSteps={tourSteps}
          getPromotionAction={getPromotionAction}
          promotions={promotions}
          rollover={rollover}
          displayAdd={displayAdd}
          getPlayerRolloverAction={getPlayerRolloverAction}
          casinoBonuses={casinoBonuses}
          ptpiGetCasinoBonusesAction={ptpiGetCasinoBonusesAction}
          casinoBonus={casinoBonus}
          ptpiGetCasinoBonusAction={ptpiGetCasinoBonusAction}
          setCashierDialogAction={setCashierDialogAction}
          cashierDialog={cashierDialog}
          getTvChannelsAction={getTvChannelsAction}
          tvChannelsList={tvChannelsList}
          isLoading={isLoading}
          runTour={runTour}
          SetRunTourAction={SetRunTourAction}
          toggleVerifyIdentityDialogAction={toggleVerifyIdentityDialogAction}
          identityDialogOpen={identityDialogOpen}
          shareable_url={shareable_url}
          createCognitoRetryAction={createCognitoRetryAction}
          SetOpenDialogTransactionContestAction={SetOpenDialogTransactionContestAction}
          clearCognitoUrlAction={clearCognitoUrlAction}
        />
      )}

      <div
        className={clsx({
          [classes.content]: isLoggedIn,
        })}
      >
        <Hidden mdDown>{isLoggedIn && <NavBarContainer />}</Hidden>
        <Hidden lgUp>
          {isLoggedIn && (
            <NavBarBottom
              isTVOn={tvOnOffResponses}
              tvOnOffAction={tvOnOffAction}
              tvUrl={tvUrl}
              setTvUrlAction={setTvUrlAction}
              user={user}
              rightOpen={rightOpen}
              SetRunTourAction={SetRunTourAction}
            />
          )}
        </Hidden>
        <div
          className={clsx({
            [classes.marginTop72]: isLoggedIn,
          })}
        >
          <Routes />
        </div>
      </div>
      <ReduxToastr timeOut={2000} transitionIn='fadeIn' transitionOut='fadeOut' />

      {/* {(path == '/login' || path.includes('signup')) && (
        <Fab
          variant='extended'
          size='small'
          color='primary'
          aria-label='add'
          onClick={() => {
            openLOLiveChat();
          }}
          style={{
            margin: '0px',
            top: 'auto',
            right: '20px',
            bottom: '20px',
            left: 'auto',
            position: 'fixed',
          }}
        >
          <ForumIcon />{' '}
          <Typography variant='body2' style={{ marginLeft: '.25rem', marginRight: '.25rem' }}>
            Support
          </Typography>
        </Fab>
      )} */}
    </div>
  );
};
