import Axios from 'axios';
type MapType = { [key: string]: any };

/**
 * Holds the application settings
 */
let appSettings: MapType;

export function getAppSettingsName(): string {
  let settingsFile;
  switch (window.location.hostname) {
    case 'vipclub.lv': // producción
      settingsFile = 'app.settings.json';
      break;
    case 'wager-sandbox.vipclub.lv': // sandbox
      settingsFile = 'app.settings.sandbox.json';
      break;
    case 'localhost': // local
      settingsFile = 'app.settings.local.json';
      break;
    default:
      // just in case lol
      settingsFile = 'app.settings.json';
      break;
  }
  return settingsFile + '?v=' + new Date().getTime();
}

export function getAppSettings(): MapType {
  return appSettings;
}

/**
 * if default value is 'undefined' raises error if key is missing
 * @param key
 * @param defaultValue
 */
export function getAppSetting(key: string, defaultValue?: any): any {
  if (!appSettings[key]) {
    if (defaultValue === undefined) {
      throw new Error(`Required value for key: ${key} is missing in appSettings.`);
    }
    return defaultValue;
  }
  return appSettings[key];
}

export function setAppSettings(settings: MapType): MapType {
  appSettings = settings;
  return appSettings;
}

export function forceUpdateAppSettings() {
  Axios.get(getAppSettingsName())
    .then(response => {
      const appSettings = response.data;
      if (appSettings) setAppSettings(Object.freeze(appSettings));
    })
    .catch(error => {
      console.error(`Failed to load APP SETTINGS:`, error);
    });
}
